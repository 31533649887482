import FancySwitch from "@/Components/extension/fancy-switch";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { navigate } from "@/Hooks/Functions";
import { cn } from "@/lib/utils";
import Empty from "@/Modules/Empty";
import { LaravelPagination } from "@/Modules/LaravelPagination";
import Loader from "@/Modules/Loader";
import { useAiPromptResponse } from "@/Pages/Prompting/utils";
import { Icon } from "@iconify/react";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useHomeFlow } from "../utils";

const fetchShowcases = async (bgFilter, designFilter, page) => {
    const { data } = await window.axios.get(route('showcases.paginate', {
        order: 'created_at:desc',
        bg_type: bgFilter,
        design_type: designFilter,
        page: page,
        per_page: 21,
    }));

    return data;
}

const EachShowcase = React.memo(({ item, onSelect }) => {
    return <Card className="shadow-lg overflow-hidden cursor-pointer hover:shadow-xl duration-300 group">
        <CardContent className="aspect-auto relative bg-transparency">
            <img src={item.url_thumb} alt="Showcase" className="w-full h-full object-contain" />
            <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-primary/70 duration-300 group-hover:visible invisible transition-all">
                <Button onClick={() => onSelect(item)} className="rounded-full" variant="theme" size="xs">Choose</Button>
            </div>
        </CardContent>
    </Card>
});

export default function HeroChoosePredesign({ className = "", contentClass = "" }) {

    const [page, setPage] = useState(1);
    const [bgFilter, setBgFilter] = useState('all')
    const [designFilter, setDesignFilter] = useState('design')
    const { setStep } = useHomeFlow();
    const { setSelected } = useAiPromptResponse();

    const { data, isLoading } = useQuery({
        queryKey: ['showcase', bgFilter, designFilter, page],
        queryFn: () => fetchShowcases(bgFilter, designFilter, page)
    });

    const showcases = useMemo(() => data?.data || [], [data]);
    const links = useMemo(() => data?.links || [], [data]);

    const onPageChange = useCallback((url) => {
        const page = new URL(url).searchParams.get('page');
        setPage(page);
    }, []);

    const onShowcaseSelect = useCallback((selected) => {
        setSelected({
            url: selected.url_inline,
            id: selected.showcase_id,
            type: selected.showcase_type,
            svg: selected.is_svg
        });
        switch (selected.type) {
            case "ai-prompt-image":
                navigate(route('designer.via-design-phase', { via: 'prompts', uuid: selected.showcase_id }))
                break;
            case "template":
                navigate(route('designer.via-design-phase', { via: 'templates', uuid: selected.showcase_id }))
                break;
            case "clipart":
                navigate(route('designer.via-design-phase', { via: 'cliparts', uuid: selected.showcase_id }))
                break;
            default:
                break;
        }
    }, []);

    const bgOptions = [{ value: 'all', label: 'All' }, { value: 'with_bg', label: 'With BG' }, { value: 'no_bg', label: 'No BG' }];
    const designOptions = [{ value: 'design', label: 'Designs' }, { value: 'design_with_frame', label: 'Design With Frame' }, { value: 'design_with_text', label: 'Design With Text' }];

    return <Card className="w-full border-none">
        <CardContent className={cn("w-full flex min-h-[calc(100vh-230px)] flex-col gap-2", className)}>
            <div className="w-full md:flex-row flex-col md:justify-between justify-center gap-2 p-4 flex bottom-2 right-0">
                <div className="flex-1 flex md:flex-nowrap flex-wrap flex-row justify-start gap-4 items-center">
                    <Button className="rounded-full px-6 !text-sm" size="xs" onClick={() => setStep('create-design')}><Icon icon="mdi:arrow-left" className="text-lg" /> Create With AI</Button>
                    <FancySwitch dense
                        options={bgOptions}
                        value={bgFilter}
                        onChange={setBgFilter}
                    />
                    <FancySwitch dense
                        options={designOptions}
                        value={designFilter}
                        onChange={setDesignFilter}
                    />
                </div>
                <LaravelPagination className="w-fit" dense links={links} navigate={onPageChange} />
            </div>

            {isLoading && <div className="w-full p-4 gap-4 grid lg:grid-cols-6 md:grid-col-5 grid-cols-2">
                {Array.from({ length: 18 }, (_, index) => index).map((item) => <Loader key={item} className="col-span-1 h-[200px] rounded-xl animate-pulse"></Loader>)}
            </div>}

            {!isLoading && showcases?.length > 0 && <ScrollArea className={cn("md:h-[calc(100vh-320px)] h-screen", contentClass)}>
                <ResponsiveMasonry className="w-full p-4" columnsCountBreakPoints={{ 350: 2, 800: 4, 1024: 6, 1366: 7, 2000: 8 }}>
                    <Masonry gutter="1em">
                        {showcases.map((item) => <EachShowcase item={item} key={item.showcase_id} onSelect={onShowcaseSelect} />)}
                    </Masonry>
                </ResponsiveMasonry>
            </ScrollArea>}

            {!isLoading && showcases?.length === 0 && <Empty className="w-full min-h-[60vh] flex items-center justify-center">
                No Result Found!
            </Empty>}
        </CardContent>
    </Card>
}
